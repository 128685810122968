<template>
    <div v-loading="loading" element-loading-background="rgba(122, 122, 122, 0.1)" element-loading-text="Please wait"
        :element-loading-spinner="svg" element-loading-svg-view-box="-10, -10, 50, 50" style="z-index: 1">
        <div class="card card-lead-by-sources mb-5">
            <!-- v-model:modelPolicyStatus="filter.policy_status" -->
            <div class="container container-lead bg-white border-radius-4">
                <div class="card-header p-0">
                    <div class="
                        d-flex
                        align-items-center
                        justify-content-between
                        w-100
                        spacing-b-15
                        ">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="form-select-custom form-select-align">
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select filterable v-model="filter.year" placeholder="Select Year" size="large" style="width: 100%" @change="getMonthsByYear">
                                        <el-option
                                            v-for="item in yearFilter"
                                            :key="item.id"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </el-select>
                                </div>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select filterable v-model="filter.year_month" placeholder="Select Month"
                                    size="large" style="width: 100%" @change="getCampaignsByYearMonth">
                                    <el-option label="Select Month" value="" />
                                    <el-option v-for="item in monthFilter" :key="item.id" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </div>

                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select filterable v-model="filter.campaign" placeholder="Select Campaign"
                                    size="large" style="width: 100%" @change="getCampaignAmountSpend">
                                    <el-option label="Select Campaign" value="" />
                                    <el-option v-for="item in campaignFilter" :key="item.id" :label="item.label"
                                        :value="item.value" />
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            
            <div class="d-flex justify-content-between align-items-center leads-indicator mb-5 me-5 ">
                <span class="text-info text-italic fw-bold d-block ">
                

                </span>
                <a class="leads nav-link alert bg-light-danger align-items-center leads-btn">
                    Total: $ {{ amountSpend ? common.formatCurrency(totalSpend) : 0 }}
                </a>
            </div>
        </div>
        <div class="card card-lead-by-sources">
            <div class="card-body card-custom-spacing-tblf-10 bg-white mb-10">
                <div class="pt-0 p-0" v-if="amountSpend && amountSpend.tracking && amountSpend.tracking.length > 0">
                    <div id="displayResponseData" class="row px-5">
                        <template v-for="track in amountSpend.tracking">
                            <div class="col-md-2 mb-5">
                                <div>
                                    <label class="col-form-label" >{{ track.campaign_date }} <span style="font-weight: 100;">({{ track.day_of_week }})</span></label>
                                    <input
                                    type="text"
                                    class="form-control"
                                    placeholder="AED e.g 10"
                                    v-model="track.spend_amount"
                                    :disabled="track.is_editable"
                                    @blur="addUpdateMarketingSpend(track)"
                                    maxlength="9"
                                    oninput="this.value = this.value.replace(/[^0-9\.\,]/g, '')"
                                    />
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-else>
                    <div v-if="loading">
                        <span>Loading Information Please Wait...</span>
                    </div>
                    <div v-else class="container-fluid p-5">
                        <div class="row">
                            <div class="col-12">
                                <el-empty description="No Records Found" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref, watch, computed } from "vue";
    import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
    import Report from "@/core/services/Callable/Report";
    import { campaign } from "@/core/utils/campaign";
    import { store } from "@/store";
    import moment from "moment";
    import { common } from "@/store/stateless";
    import Swal from "sweetalert2/dist/sweetalert2.js";

    const filter = ref({
        campaign: "",
        year_month: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0'),
        year: new Date().getFullYear(),
    });

    
    const yearFilter = ref([]);
    const monthFilter = ref([]);
    const campaignFilter = ref([]);
    const responseFilter =  ref([])

    const loading = ref(false);
    const error = ref(false);

    const amountSpend = computed(() => {
        return store.getters.getCampaignAmountSpend;
    });

    const totalSpend = computed(() => {
       if(amountSpend.value.tracking){ 
        const spend = amountSpend.value.tracking.reduce((sum , x) => sum + parseFloat(x.spend_amount) , 0)
        return spend
       }
       return 0
    })

    onMounted(async () => {
        setCurrentPageBreadcrumbs("Marketing Spend", []);
        await spendFilters()
        getCampaignAmountSpend()
    });

    async function spendFilters() {
        loading.value = true
        responseFilter.value = await Report.spendCampaignFilters({})
        yearFilter.value = responseFilter.value.years
        monthFilter.value = responseFilter.value.months
        campaignFilter.value = responseFilter.value.campaigns
        loading.value = false
    }

    async function getMonthsByYear() {
        // datas.value = []
        await resetCampaignAmountSpend()
        filter.value.year_month = ''
        filter.value.campaign = ''
        const params = {
            year:filter.value.year,
        }
        responseFilter.value = await Report.spendCampaignFilters(params)
        monthFilter.value = responseFilter.value.months
    }

    async function getCampaignsByYearMonth() {
        // datas.value = []
        await resetCampaignAmountSpend()
        filter.value.campaign = ''
        const params = {
            year_month:filter.value.year_month,
        }
        responseFilter.value = await Report.spendCampaignFilters(params)
        campaignFilter.value = responseFilter.value.campaigns
    }
    const getCampaignAmountSpend = async () => {
        
        loading.value = true;
        await Report.getCampaignAmountSpend(filter.value);
        loading.value = false;
    };

    async function addUpdateMarketingSpend(track) {
        // console.log('track',track)
        try {
            const params = {
                campaign:filter.value.campaign,
                year:track.year,
                month:track.month,
                day:track.day,
                spend_amount:track.spend_amount
            }
            const response = await Report.addUpdateMarketingSpend(params)
            // await Report.getCampaignAmountSpend(filter.value)


            
        } catch (error) {
            return error
        }
    }

    const resetCampaignAmountSpend = async () => {
        const payload = {}
        await Report.setCampaignAmountSpend(payload)
    }
</script>
